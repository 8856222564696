body {
  font-family: Orbitron, sans-serif;
}

.vid-container {
  width: 100%;
  max-width: 1000px;
  height: auto;
  min-height: 200px;
  max-height: 562.5px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  padding: 500px 0 0;
  position: relative;
}

.vid-player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#popup-container {
  z-index: 1000;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#popup-content {
  background-color: #fff;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  padding: 20px;
  overflow-y: scroll;
}

.close-btn {
  float: right;
  cursor: pointer;
}

.tabs {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  display: flex;
}

.tab-content {
  border: 1px solid #ddd;
  border-top: none;
  padding: 20px;
  display: none;
}

.cookie-pref {
  color: #60a89f;
  background-color: #0f0c29;
  border: 2px solid #60a89f;
  border-radius: 5px;
  width: fit-content;
  margin: 20px auto;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: block;
}

.cookie-pref:hover {
  color: #0f0c29;
  background-color: #60a89f;
}

#custom-popup-container {
  z-index: 1000;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.popup-content {
  background-color: #7a7a7a;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px #0003;
}

.close-popup-btn {
  float: right;
  cursor: pointer;
}

.popup-message {
  margin-top: 20px;
}

@media only screen and (width <= 768px) {
  .vid-container {
    padding: 56.25% 0 0;
  }
}
/*# sourceMappingURL=index.f43eed2c.css.map */
