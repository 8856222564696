body {
  font-family: 'Orbitron', sans-serif;
}

.vid-container {
  padding: 500px 0 0 0; 
  position: relative;
  margin-top: 25px;
  max-width: 1000px;
  max-height: 562.5px;
  min-height: 200px;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.vid-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;
}

#popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-height: 80%;
  max-width: 80%;
  overflow-y: scroll;
}

.close-btn {
  float: right;
  cursor: pointer;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.tab-content {
  display: none;
  padding: 20px;
  border: 1px solid #ddd;
  border-top: none;
}

.cookie-pref {
  display: block;
  width: fit-content;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #0f0c29;
  color: #60a89f;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid #60a89f;
  transition: background-color 0.3s, color 0.3s;
}

.cookie-pref:hover {
  background-color: #60a89f;
  color: #0f0c29;
}

#custom-popup-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #7a7a7a;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.close-popup-btn {
  float: right;
  cursor: pointer;
}

.popup-message {
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .vid-container {
    padding: 56.25% 0 0 0; 
  }
}
